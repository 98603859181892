import request from '@/utils/request'

//获取新闻列表
export function getNews(params) {
  return request({ url: '/web/mch/official/article/page', method: 'get', params})
}

//获取新闻头图
export function getNewsBanner(params) {
  return request({ url: '/web/mch/official/news', method: 'get', params})
}

//获取新闻详情
export function getNewsDetail(params) {
  return request({ url: `/web/mch/official/article/${params}`, method: 'get'})
}